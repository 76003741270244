<template>
  <div class="room-list-page">
    <section class="for-rt">
      <div class="container">
        <div class="room-title">Room List</div>
      </div>
    </section>

    <section class="show-item">
      <div class="container">
        <div class="filter-row">
          <div class="ft-filter">Showing {{ startRow }} - {{ untilRow }} of {{ totalRows }} list</div>
          <div class="button-filter">
            <template v-if="$store.getters.userType == 'master'">
              <router-link to="/room/manage/create" class="btn btn-orange w-100">Create Room</router-link>
            </template>
            <template v-else>
              <div class="bf-item mx-2">
                <select class="form-control select-filter" v-model="region">
                  <option :value="null">Region</option>
                  <option v-for="(region, index) in regions" :key="index" :value="region.id">{{ region.name }}</option>
                </select>
              </div>
              <div class="bf-item">
                <select class="form-control select-filter" v-model="topic">
                  <option :value="null">Topic</option>
                  <option v-for="(topic, index) in topics" :key="index" :value="topic.id">{{ topic.name }}</option>
                </select>
              </div>
            </template>
          </div>
        </div>
      </div>
    </section>

    <section class="room-items">
      <div class="container">
        <template v-for="(room, index) in rooms">
          <div class="list-ri" :key="index">
            <div class="row">
              <div class="col-lg-6 col-sm-12">
                <div class="room-desc">
                  <router-link :to="`/room/${room.slug}`" class="link-rh">
                    {{ room.subject_topic }}
                  </router-link>
                  <p>{{ room.case_overview | short_description(270) }}</p>
                  <div class="row align-items-center">
                    <div class="col-sm-5">
                      <div class="participant">
                        <div>Current Participant</div>
                        <div class="mx-2">:</div>
                        <div class="dark"><strong>{{ room.total_participant_user }}</strong></div>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <div class="participant country">
                        <div>Country List</div>
                        <div class="mx-2">:</div>
                        <div><strong>{{ room.total_participant_country }}</strong></div>
                        <div class="mx-3">
                          <router-link :to="`/room/${room.slug}`" class="btn btn-rd">
                            <span class="icon-arrow-right"></span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6">
                <div class="img-room">
                  <figure>
                    <router-link :to="`/room/${room.slug}`">
                      <img :src="room.banner_url" class="img-fluid">
                    </router-link>
                  </figure>
                </div>
              </div>
              <div class="col-lg-2 col-sm-6">
                <template v-if="$store.getters.userType == 'master'">
                  <div class="mar-btn">
                    <div class="row-lb">
                      <div class="row-lb-item">
                        <button type="button" @click="$router.push(`/room/manage/edit/${room.id}`)" class="btn btn-edit w-100">Edit</button>
                      </div>
                      <div class="row-lb-item">
                        <button class="btn btn-delete w-100" @click="deleteId = room.id">Delete</button>
                      </div>
                    </div>

                    <button type="button" id="publish" class="btn btn-orange w-100" @click="publishId = room.id">Publish</button>
                  </div>
                </template>
                <template v-else>
                  <div class="mt-4">
                    <div class="date">Registration Date</div>
                    <div class="date-info mb24">{{ room.registration_date }}</div>

                    <div class="date">Simulation Date</div>
                    <div class="date-info mb24">{{ room.simulation_date }}</div>
                    <template v-if="$store.getters.userType == 'student'">
                      <button type="button" class="btn btn-orange w-100" @click="showFormJoin(room.id)">Join</button>
                    </template>
                    <template v-else>
                      <a :href="`${SSO_URL}/Shibboleth.sso/Login`" class="btn btn-orange w-100">Join</a>
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>

        <div class="d-flex justify-content-center">
          <ul class="pagination pagination-rounded mb-0">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
          </ul>
        </div>
      </div>
    </section>

    <!-- popup confirm delete -->
    <div class="popup-overlay" :class="{ show: deleteId}">
      <div class="inner-delete" :class="{ popup: deleteId}">
        <div class="title-info">Confirmation</div>
        <p>Are you sure want to delete this room ?</p>
        <div class="row g-2">
          <div class="col-6">
            <button type="button" class="btn btn-delete w-100" id="cancel-delete" @click="deleteId = null">Cancel</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-delete-pink w-100" id="submit-delete" @click="remove">Delete</button>
          </div>
        </div>
      </div>
    </div>

    <!-- popup confirm publish -->
    <div class="popup-overlay" :class="{ show: publishId}">
      <div class="inner-delete" :class="{ popup: publishId}">
        <div class="title-info">Confirmation</div>
        <p>Are you sure want to publish this room ?</p>
        <div class="row g-2">
          <div class="col-6">
            <button type="button" class="btn btn-delete w-100" id="cancel-publish" @click="publishId = null">Cancel</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-orange w-100" id="submit-publish" @click="publish">Yes</button>
          </div>
        </div>
      </div>
    </div>

    <!-- popup confirm join -->
    <div class="popup-overlay" :class="{ show: joinId }">
      <div class="inner-delete" :class="{ popup: !finishJoin && joinId !== null }">
        <form @submit.prevent="join" id="form-join-room">
          <div class="title-info mb-3">Simulation Registration</div>
          <div class="label-popup mb-2">Choose Country</div>
          <select class="form-select mb-3" id="join-country" v-model="joinForm.country">
            <option :value="null">Country</option>
            <option v-for="(country, i) in countries" :key="i" :value="country.id">{{ country.name }}</option>
          </select>
          <div class="label-popup mb-2">Choose Class</div>
          <input type="text" class="form-control mb-4" id="join-class" v-model="joinForm.class" required>
          <div class="row g-2">
            <div class="col-6">
              <button type="button" class="btn btn-delete  btn-radius w-100" id="cancel-join" @click="joinId = null, finishJoin = false">Cancel</button>
            </div>
            <div class="col-6">
              <button type="submit" class="btn btn-orange btn-radius for-submit w-100" id="submit-join">Submit</button>
            </div>
          </div>
        </form>
      </div>

      <div class="box-thanks" :class="{ show: finishJoin }">
        <img src="/img/check.svg" class="img-fluid">
        <div class="ttl-oke">Registration Completed Successfully</div>
        <p>Please check ‘Simulation’ for the detail information</p>
        <button type="button" class="btn btn-orange btn-radius for-ok" id="ok" @click="successJoin">Ok</button>
      </div>
    </div>
  </div>
</template>

<script>
//import $ from "jquery";
import Swal from "sweetalert2";

export default {
  name: "RoomList",
  data() {
    return {
      deleteId: null,
      publishId: null,
      joinId: null,
      finishJoin: false,
      joinForm: {
        country: null,
        class: null
      },
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      sortBy: "created_at",
      sortDesc: false,
      rooms: [],
      region: null,
      topic: null,
      regions: [],
      topics: [],
      countries: []
    }
  },
  mounted() {
    //$('.select-filter').selectric();
    // $(".for-delete").click(function () {
    //   $('.popup-overlay').addClass("show");
    // });
    this.fetchRooms();
    this.fetchRegions();
    this.fetchTopics();
  },
  watch: {
    currentPage(){
      this.fetchRooms()
    },
    topic() {
      if (this.currentPage == 1) {
        this.fetchRooms()
      } else {
        this.currentPage = 1
      }
    },
    region() {
      if (this.currentPage == 1) {
        this.fetchRooms()
      } else {
        this.currentPage = 1
      }
    }
  },
  computed: {
    startRow() {
      if (this.currentPage == 1) {
        return 1
      } else {
        return (this.perPage * (this.currentPage - 1 )) + 1
      }
    },
    untilRow() {
      if ((this.currentPage * this.perPage) <= this.totalRows) {
        return this.currentPage * this.perPage
      } else {
        return this.totalRows
      }
    }
  },
  methods: {
    fetchRegions() {
      this.$axios.get('/cms/region', {
        params: {
          per_page: 100,
          sortBy: 'name',
          sortType: 'asc'
        }
      })
        .then(response => {
          this.regions = response.data.data.data
        })
    },
    fetchTopics() {
      this.$axios.get('/cms/category_topic', {
        params: {
          per_page: 100,
          sortBy: 'name',
          sortType: 'asc'
        }
      })
        .then(response => {
          this.topics = response.data.data.data
        })
    },
    fetchRooms() {
      let urlRequest = null
      if (this.$store.getters.userType == 'master') {
        urlRequest = '/master/room'
      } else {
        urlRequest = '/student/room'
      }
      this.$axios.get(urlRequest, {
        params: {
          page: this.currentPage,
          per_page: this.perPage,
          search: this.filter,
          sortBy: this.sortBy,
          sortType: (this.sortDesc)? 'asc' : 'desc',
          category_topic_id: this.topic,
          region_id: this.region
        }
      })
        .then(response => {
          this.rooms = response.data.data.data
          this.totalRows = response.data.data.meta.total
        })
    },
    remove() {
      let param = new FormData();
      param.append('_method', 'delete');
      param.append('id', this.deleteId);
      this.$axios.post('/master/room', param)
        .then(response => {
          console.log(response);
          Swal.fire("Success", response.data.message, "success");
          this.fetchRooms();
          this.deleteId = null
        })
    },
    showFormJoin(roomId) {
      this.$axios.get(`/master/room/${roomId}`)
        .then(response => {
          this.joinId = roomId;
          const room = response.data.data
          this.countries = room.participants
        })
    },
    join() {
      this.$axios.post(`/simulation/student/join`, {
        room_id: this.joinId,
        requested_participant_id: this.joinForm.country,
        class: this.joinForm.class
      }).then(response => {
        console.log(response)
          this.finishJoin = true
        })
    },
    successJoin() {
      this.joinId = null 
      this.finishJoin = false
      this.$router.push('/simulation')
    },
    publish() {
      this.$axios.patch('/master/room/publish', {
        id: this.publishId
      })
        .then(response => {
          console.log(response)
          this.fetchRooms();
          this.publishId = null
        })
    }
  }
}
</script>